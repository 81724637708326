import React, { useContext, useEffect, useState } from 'react'
import UpdateDrugTestPopup from './UpdateDrugTestPopup'
import CustomerContext from '../Context/CustomerContext'

function UpdateDrugTestPopupLogic({ show, setShow, candidateInfo }) {
    const [isApproved, setIsApproved] = useState();
    const [notes, setNotes] = useState();
    const [drugTest, setDrugTest] = useState();
    const [date, setDate] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [drugTestOptions, setDrugTestOptions] = useState();
    const [submitted, setSubmitted] = useState(false);
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const {cookies} = useContext(CustomerContext)

    useEffect(()=>{
      const GetDrugTestTypes = (token) => { 
        if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/GetDrugTestTypes?token=${token}`)
        .then(res=>{
            if(res.status===200){
                return res.json()
            }else if(res.status===401){
                cookies.remove('customer')
                window.location.reload()
            }else if(!res.ok){
                throw Error(res.statusText)
            }
        })
        .then(data=>{
            let formattedOptions = data.map(option=>{
              return {value:option,label:option.DrugTestType}
            })
            setDrugTestOptions(formattedOptions)
        })
        .catch(error=>{
            console.error(error)
            window.alert(error)
        })
       }
      if(!drugTestOptions){
        GetDrugTestTypes(cookies.get('customer').Token)
      }
    },[drugTestOptions])


    const HandleSubmit = (event) => { 
      let postObj = {
        EmployeeID: candidateInfo.employeeid,
        IsApproved: isApproved,
        Type: drugTest,
        Date: date+'T00:00:00.000',
        Notes: notes
      }
      if(ValidatePostObj()){
        setLoading(true);
        PostDrugTestResponse(cookies.get('customer').Token, postObj);
      }
     }
    
     const ValidatePostObj = () => { 
      if(isApproved==null){
        setError('Please select Positive or Negative')
        return false;
      }else if(!date){
        setError('Please add a date')
        return false;
      }else if(!drugTest){
        setError('Please select a drug test type')
        return false;
      }else{
        return true;
      }
   }

    const PostDrugTestResponse = (token, postObj) => { 
      if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/PostDrugTestResponse?token=${token}`,{
      method: 'POST',
      headers: {
          'Content-Type':'application/json'
      },
      body: JSON.stringify(postObj)
      })
      .then(res=>{
          if(res.status===200){
            setSubmitted(true)
            setLoading(false)
          }else if(res.status===401){
              cookies.remove('customer')
              window.location.reload()
          }else if(!res.ok){
              throw Error(res.statusText)
          }
      })
      .catch(error=>{
          setLoading(false);
          console.error(error)
          window.alert(error)
      })
     }

    const HandleChange = (event, fieldName) => { 
      setError(null);
      if(fieldName==='DrugTest'){
        setDrugTest(event.value.DrugTestType)
      }else if(fieldName==='Date'){
        let newDate = event.target.value
        setDate(newDate)
      }else{
        setNotes(event.target.value)
      }
     }
    
    const PostDrugTestType = (token, drugTestName) => { 
      if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/putdrugtesttype?token=${token}&drugtesttype=${drugTestName}`,{
      method: 'PUT',
      headers: {
          'Content-Type':'application/json'
      }
      })
      .then(res=>{
          if(res.status===200){
              return res.json()
          }else if(res.status===401){
              cookies.remove('customer')
              window.location.reload()
          }else if(!res.ok){
              throw Error(res.statusText)
          }
      })
      .then(data=>{
          let formattedOptions = data.map(option=>{
            return {value:option,label:option.DrugTestType}
          })
          setDrugTestOptions(formattedOptions)
      })
      .catch(error=>{
          console.error(error)
          window.alert(error)
      })
     }

  return (
    <UpdateDrugTestPopup
      show={show}
      setShow={setShow}
      setNotes={setNotes}
      setIsApproved={setIsApproved}
      loading={loading}
      HandleSubmit={HandleSubmit}
      HandleChange={HandleChange}
      error={error}
      drugTestOptions={drugTestOptions}
      submitted={submitted}
    ></UpdateDrugTestPopup>
  )
}

export default UpdateDrugTestPopupLogic