import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label, subject, body }) => {  

    return (
        <Link
            className="link-button btn btn-primary"
            to='#'
            onClick={(e) => {
                window.location.href = `mailto:${mailto}?body=${body}&subject=${subject}`;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};
export default ButtonMailto;