import React from 'react'
import { Button, Form, Modal, ModalBody } from 'react-bootstrap'

function ConfirmationPopup({ show, setShow, emails}) {
  return (
    <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton></Modal.Header>
        {(emails.length>0)?
        <ModalBody>
            <h3 className='text-center'>The following contacts were not found</h3>
            <p className='text-center'>Check the spelling and try again</p>
            <Form.Control readOnly value={emails} as='textarea'/>
        </ModalBody>
        :
        <ModalBody className='text-center'>
            <h3>All Contacts marked as inactive</h3>
        </ModalBody>
        }
        <Modal.Footer>
            {emails.length>0&&
            <Button variant='secondary' onClick={() => {navigator.clipboard.writeText(emails)}}>Copy Emails to clipboard</Button>
            }
            <Button onClick={(e)=>setShow(false)}>Close</Button>
        </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationPopup