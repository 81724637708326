import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Footer from './Footer'
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';

const Layout = ({children}) => {

    return (
      <div style={{ 'minHeight':'90vh' }}>
        <NavMenu />
        <Container>
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </Container>
        <Footer />
      </div>
    );
}

export default Layout;