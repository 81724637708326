import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import '../../../Styles/App.css'
import { propTypes } from "react-bootstrap/esm/Image";
import { CSVLink, CSVDownload } from "react-csv";
import ButtonMailto from "../../CustomComponents/Emailer/EmailButton";
import Select from 'react-select';


export default function CheckRequestView({
  weDate, 
  setWeDate,
  custName,
  setCustName, 
  empName, 
  setEmpName,
  eeID, 
  setEeID,
  asnID, 
  setAsnID, 
  requestID, 
  setRequestID,
  regHours,
  setRegHours,
  otHours,
  setOTHours,
  dtHours,
  setDTHours,
  payStatus,
  setPayStatus, 
  billStatus,
  setBillStatus,
  csvData, 
  setCsvData, 
  body,
  setBody,
  handleSubmit,
  newArray,
  emailString}) {

  return (
    <Form className="cr-form" onSubmit={(e)=>handleSubmit(e)}>
      <h1>Check Request Form</h1>
      <br/>
      <Row className="form-row">
        <Form.Group as={Col}>
          <Form.Control onChange={(e)=>setWeDate(e.target.value)} value={weDate} type="date"/>
        </Form.Group>
        <Col>
          <Form.Control type="text" onChange={(e)=>setCustName(e.target.value)} value={custName} placeholder="Customer Name"/>
        </Col>
        <Col>
          <Form.Control type="text" onChange={(e)=>setEmpName(e.target.value)} value={empName} placeholder="Employee Name"/>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col>
          <Form.Control type="text" onChange={(e)=>setEeID(e.target.value)} value={eeID} placeholder="Employee ID"/>
        </Col>

        <Col>
          <Form.Control type="text" onChange={(e)=>setAsnID(e.target.value)} value={asnID} placeholder="Assignment ID"/>
        </Col>
        
        <Col>
          <Form.Control type="text" onChange={(e)=>setRequestID(e.target.value)} value={requestID} placeholder="Request Reason"/>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col xs="auto">
          <Form.Control type="text" onChange={(e)=>setRegHours(e.target.value)} value={regHours} placeholder="Reg Hours"/>
        </Col>

        <Col xs="auto">
          <Form.Control type="text" onChange={(e)=>setOTHours(e.target.value)} value={otHours} placeholder="OT Hours"/>
        </Col>

        <Col xs="auto">
          <Form.Control type="text" onChange={(e)=>setDTHours(e.target.value)} value={dtHours} placeholder="DT Hours"/>
        </Col>
        <Col>
          <Form.Select onChange={(e)=>setPayStatus(e.target.value)} value={payStatus}>
            <option>Pay Type</option>
            <option value="dd">Direct Deposit</option>
            <option value='live'>Live Check</option>
            <option value='branch'>Print to Branch</option>
          </Form.Select>
        </Col>

        <Col>
        <Form.Select onChange={(e)=>setBillStatus(e.target.value)} value={billStatus}>
            <option>Bill Type</option>
            <option value="bill">Bill</option>
            <option value='no Bill'>No Bill</option>
            <option value='bill Only'>Bill Only</option>
          </Form.Select>
        </Col>
      </Row>

      <Form.Group className="inputgapped" >
      <br/>
      <Row className="buttonBox">
        <Col>
          <Button className="link-button" variant="primary" type="submit">
            Submit
          </Button>
        </Col>
        <Col>
          <CSVLink className="link-button btn btn-primary" data={csvData}>Download CSV</CSVLink>
        </Col>
        <Col>
          <ButtonMailto label="Email Request" mailto="jcampbell@employnet.com" body={body} subject="New Check Request" />
        </Col>
      </Row>
      </Form.Group>

    </Form>

    );
}