import React from 'react'
import { Alert, Button, Modal, Form } from 'react-bootstrap'
import Select from 'react-select'

function UpdateBackgroundPopup({ submitted, formInfo, error, setNotes, setIsApproved, show, setShow, loading, HandleSubmit }) {
  return (
    <Modal show={show} onHide={setShow}>
      {submitted?
      <>
      <Modal.Header closeButton>
          <h3>Update Background Status</h3>
      </Modal.Header>
      <Modal.Body>
          <h6>Request has been submitted succesfully!</h6>
          <p><b>It takes up to 15 minutes for the information that was just submitted to sync. Please do not put this request in again until 15 minutes have passed and you have refreshed the grid. Thank you.</b></p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={(e)=>setShow(false)} variant='secondary'>Close</Button>
      </Modal.Footer>
      </>
      :
      <>
      <Modal.Header closeButton>
          <h3>Update Background Status</h3>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Label>Approved/Rejected<span>*</span></Form.Label>
          <Select options={[{value:true,label:'Reviewed and Approved'},{value:false,label:'Reviewed and Rejected'}]} onChange={(e)=>setIsApproved(e.value)} />

          <Form.Label>Date of background check<span>*</span></Form.Label>
          <Form.Control type='date' disabled value={formInfo.DateCreated.split('T')[0]}></Form.Control>

          <Form.Label>Background test type<span>*</span></Form.Label>
          <Form.Control disabled value={formInfo.Summary} />

          <Form.Label>Notes:</Form.Label>
          <Form.Control onChange={(e)=>setNotes(e.target.value)} />
          {error&&
          <Alert variant='warning'>{error}</Alert>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={HandleSubmit}>{loading?'Submitting...':'Submit'}</Button>
        <Button onClick={(e)=>setShow(false)} variant='secondary'>Close</Button>
      </Modal.Footer>
      </>
      }
    </Modal>
  )
}

export default UpdateBackgroundPopup