import React, { useState, useEffect, useContext } from "react";
import CustomerContext from "../../Context/CustomerContext";
import InvoiceAgingView from "./InvoiceAgingView";
import useFetchGet from "../../CustomHooks/useFetchGet";
import CustomerInvoices from "./CustomerInvoices";


export default function InvoiceAging(){
    const { cookies, setCurrentPage } = useContext(CustomerContext);
    const { REACT_APP_PAYROLL_API_URL } = process.env;
    const { REACT_APP_ORDER_REQUEST_API_URL} = process.env;
    const [showSidebar, setShowSidebar] = useState(false);
    const [company, setCompany] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [department, setDepartment] = useState();
    const [pastDue, setPastDue] = useState();
    const [selectedPastDue, setSelectedPastDue] = useState();
    const [payTerms, setPayTerms] = useState();
    const [selectedPayTerms, setSelectedPayTerms] = useState();
    const [invoiceNumber, setInvoiceNumber] = useState();
    const [invoiceDate, setInvoiceDate] = useState();
    const [balanceAmount, setBalanceAmount] = useState();
    const [gridApi, setGridApi] = useState();
    const [customers, setCustomers] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [rowData, setRowData] = useState();
    const [rowSelection, setRowSelection] = useState('multiple');
    const [contactPopupIsOpen, setContactPopupIsOpen] = useState(false);
    const [selectedCustomerID, setSelectedCustomerID] = useState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [showMailer, setShowMailer] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState();
    const abortController = new AbortController();
    const [modalController, setModalController] = useState(0);
    //const [userIDCounter, setUserIDCounter] = useState(0);

    const [defaultColDef] = useState ({
        sortable: true,
        resizable: true,
        filter: true,       
    });

    const pastDueParams = [
      {value: 30, label:"0-30"},
      {value: 60, label:"31-60"},
      {value: 90, label:"61-90"},
      {value: 120, label:"91-120"},
      {value: 121, label:"121+"}
    ];

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const [colDefs] = useState([
        {field: 'CompanyName', headerName: 'Company', checkboxSelection: true, headerCheckboxSelection: true },
        {field: 'customerName', headerName: 'Customer'},
        {field: 'CustomerID', headerName: 'Customer ID'},
        {field: 'DepartmentName', headerName: 'Department'},
        {field: 'paymentTerms'},
        {field: 'invoiceNumber'},
        {field: 'invoiceDate', valueFormatter: (data)=>FormatDate(data)},
        {field: 'PastDueInDays'},
        {field: 'BalanceAmount', valueFormatter: (data)=>FormatUSD(data)},
    ]);

    const { data } = useFetchGet(`${REACT_APP_PAYROLL_API_URL}/getinvoiceaging?token=${cookies.get('customer').Token}`, cookies);

    // sets the original display data in the grid, and creates the filter values
    useEffect(()=>{
        if(data){
          setRowData(data)
          setPinnedBottomRowData([{
            BalanceAmount: `${Reduce(data, 'BalanceAmount')}`,
          }]);

          let filteredCompanies = Object.values(
            data.reduce( (c, e) => {
              if (!c[e.CompanyName]) c[e.CompanyName] = e;
              return c;
            }, {})
          );

          let formattedCompanies = filteredCompanies.map((company)=>{
            return {value:company, label:company.CompanyName}
          });
          setCompany(formattedCompanies);

          let filteredData = Object.values(
            data.reduce( (c, e) => {
              if (!c[e.customerName]) c[e.customerName] = e;
              return c;
            }, {})
          );

          let formattedCustomers = filteredData.map((customer)=>{
            return {value:customer, label:customer.customerName}
          });
          setCustomers(formattedCustomers);

          let formattedPastDue = pastDueParams.map((pastDueDate)=>{
            return {value:pastDueDate.value, label:pastDueDate.label}
          })
          setPastDue(formattedPastDue);

          let filteredTerms = Object.values(
            data.reduce( (c, e) => {
              if (!c[e.paymentTerms]) c[e.paymentTerms] = e;
              return c;
            }, {})
          );

          let formattedTerms = filteredTerms.map((terms)=>{
            return {value:terms, label:terms.paymentTerms}
          });
          setPayTerms(formattedTerms);

        };
    },[data]);

    // updates bottom row based on rows in grid
    useEffect(()=>{
      if(rowData&&rowData.length>0){
        setPinnedBottomRowData([{
          BalanceAmount: `${Reduce(rowData, 'BalanceAmount')}`,
        }])
      }
    },[rowData]);


    useEffect(()=>{
      setCurrentPage('InvoiceAging')
    },[]);

    // for formatting dollar values
    const FormatUSD = (data) =>{
      return `${formatter.format(data.value)}`
    }

    const FormatDate = (data) =>{
      // return date without time stamp and 'T'
      if(data.value){
        let fixedDate = data.value.split('T')[0]
        return fixedDate
      }else{
        return ''
      }
      
    };

    const Reduce = (data, dataName) => { 
       let total = data.reduce((last,current)=>last+current[dataName],0).toFixed(2)
       return total
     };

    const onGridReady = (params) => { 
      setGridApi(params.api);
      params.columnApi.autoSizeAllColumns();
    };

    const onFilterChanged = (e) => { 
        let data = [];
        gridApi.forEachNodeAfterFilter(node=>{
           data.push(node.data);
        })
        setPinnedBottomRowData([{
          BalanceAmount: `${Reduce(data, 'BalanceAmount')}`,
        }])
    };

    const HandleSubmit = (e) => {
      // Go into state and grab selected options
      // Find out which options have values
      // Filter over original data set to find which rows contain values
      // update state with rows based on selections
      let filteredList = [...data];
      if(selectedCompany){
        filteredList = filteredList.filter(row=>{
          if(row.CompanyName===selectedCompany.value.CompanyName){
            return row
          }
        })
      }
      if(selectedCustomer){
        filteredList = filteredList.filter(row=>{
          if(row.CustomerID===selectedCustomer.value.CustomerID){
            return row
          }
        })     

      }
      if(selectedPastDue){
        filteredList = filteredList.filter(row=>{
        if(selectedPastDue.value===30&&row.PastDueInDays<31){
          return row
        }
        else if(selectedPastDue.value===60&&61>row.PastDueInDays>30){
          return row
        }
        else if(selectedPastDue.value===90&&91>row.PastDueInDays>60){
          return row
        }
        else if(selectedPastDue.value===120&&121>row.PastDueInDays>90){
          return row
        }
        else if(selectedPastDue.value===121&&row.PastDueInDays>120){
          return row
        }
      })}
      if(selectedPayTerms){
        filteredList = filteredList.filter(row=>{
          if(row.paymentTerms===selectedPayTerms.value.paymentTerms){
            return row
          }
        })
      }
      setRowData(filteredList)
      setShowSidebar(false)
      };
      
    
    // Handle Change functions change row data based on filter
    const HandleCustomerChange = (e) => { 
      setSelectedCustomer(e)
     };

    const HandleCompanyChange = (e) => {
        setSelectedCompany(e)
       };

    const HandleDateChange = (e) => {
        setSelectedPastDue(e)
     };

    const HandleTermsChange = (e) => {
        setSelectedPayTerms(e)
     };

    const ExportToCSV = (params) => {
       gridApi.exportDataAsCsv(params);
     };

    const HandleShowFilters = (e) => {
       setSelectedCompany(null);
       setSelectedCustomer(null);
       setSelectedPastDue(null);
       setSelectedPayTerms(null);
       setShowSidebar(true);
     };

    const OpenInfoCard = (e) => {
       setSelectedCustomer(e.data.customerName);
       setSelectedCustomerID(e.data.CustomerID);
       setDepartment(e.data.DepartmentName);
       setPayTerms(e.data.paymentTerms);
       setInvoiceNumber(e.data.invoiceNumber);
       setInvoiceDate(e.data.invoiceDate);
       setPastDue(e.data.PastDueInDays);
       setBalanceAmount(e.data.BalanceAmount);
       setContactPopupIsOpen(true);
     };

    const CloseCard = (e) => {
       setContactPopupIsOpen(false);
     };

    const onSelectionChanged = () => {
      const selectedData = gridApi.getSelectedRows();
    };

    const EmailSelectedInvoices = (e) => {
      // collect the row data for the selected invoices
      const selectedData = gridApi.getSelectedRows();
      setSelectedRowData(selectedData);
      // iterate over all selected rows
      let isMultiple;
      for (var i = 0; i < selectedData.length; i++){
        if (selectedData[i].CustomerID !== selectedData[0].CustomerID){
          window.alert("You have selected more than one customer!", "OK");
          isMultiple = true;
          break;
        }
        else{
          isMultiple = false;
        }};

      if(isMultiple===false){
        console.log("Here are the goods: " + selectedData[0].CustomerID);
        setSelectedCustomerID(selectedData[0].CustomerID);
        setModalController(1);
        setShowMailer(true);}
        
      // if the same customerid shows up on more than 1 row, throw error
      
      // see "Contact List" for next functional steps
     };

    const closeMailer = (e) => {
       setShowMailer(false);
     };

    return (
      // <>
      // {userIDCounter % 2 === 0?
        <InvoiceAgingView  
          company={company}
          setCompany={setCompany}
          pastDue={pastDue}
          setPastDue={setPastDue}
          rowData={rowData}
          onGridReady={onGridReady}
          colDefs={colDefs}
          defaultColDef={defaultColDef}
          pinnedBottomRowData={pinnedBottomRowData}
          onFilterChanged={onFilterChanged} 
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          customers={customers}
          selectedCustomer={selectedCustomer}
          HandleCustomerChange={HandleCustomerChange}
          selectedCompany={selectedCompany}
          HandleCompanyChange={HandleCompanyChange}
          selectedPastDue={selectedPastDue}
          HandleDateChange={HandleDateChange}
          HandleSubmit={HandleSubmit}   
          ExportToCSV={ExportToCSV}   
          HandleShowFilters={HandleShowFilters}
          rowSelection={rowSelection}
          payTerms={payTerms}
          selectedPayTerms={selectedPayTerms}
          HandleTermsChange={HandleTermsChange}
          OpenInfoCard={OpenInfoCard}
          contactPopupIsOpen={contactPopupIsOpen}
          setContactPopupIsOpen={setContactPopupIsOpen}
          CloseCard={CloseCard}
          selectedCustomerID={selectedCustomerID}
          department={department}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          balanceAmount={balanceAmount}
          EmailSelectedInvoices={EmailSelectedInvoices}
          selectedRowData={selectedRowData}
          showMailer={showMailer}
          setShowMailer={setShowMailer}
          closeMailer={closeMailer}
          onSelectionChanged={onSelectionChanged}
          //ClearFilters={ClearFilters}
          modalController={modalController}
          setModalController={setModalController}
          // userIDCounter={userIDCounter}
          // setUserIDCounter={setUserIDCounter}

        />
      //   {/* :
      //   <CustomerInvoices
      //     rowData={rowData}
      //     onGridReady={onGridReady}
      //     colDefs={colDefs}
      //     defaultColDef={defaultColDef}
      //     pinnedBottomRowData={pinnedBottomRowData}
      //     onFilterChanged={onFilterChanged} 
      //     userIDCounter={userIDCounter}
      //     setUserIDCounter={setUserIDCounter}
      //   />
      // } */}
      // </>

    );
};