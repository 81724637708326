import React from 'react'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import ConfirmationPopupLogic from './ConfirmationPopup/ConfirmationPopupLogic'
import './EmailImport.css'

function EmailImport({ loading, message, HandleChange, HandleSubmit, confirmationPopupIsOpen, setConfirmationPopupIsOpen, nonDeletedEmails }) {
  return (
    <>
    {(nonDeletedEmails)&&
    <ConfirmationPopupLogic show={confirmationPopupIsOpen} setShow={setConfirmationPopupIsOpen} emails={nonDeletedEmails} />
    }
    <Card className='padding align-center'>
      <Card.Header>
        <h3>Disable Contacts</h3>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Label>Copy/paste emails below, one email per line.</Form.Label>
          <Form.Label>After submission, all Tempworks Contacts with corresponding emails attached to them will be disabled.</Form.Label>
          <Form.Control placeholder='example1@ex.com&#13;&#10;example2@ex.com&#13;&#10;example3@ex.com' className='email-text-area margin-bottom' as='textarea' onChange={HandleChange} />
        </Form>
        {message&&
        <Alert variant={message.variant}>{message.message}</Alert>
        }
      </Card.Body>
      <Card.Footer>
        <Button className='email-submit-button' size='lg' disabled={loading} onClick={HandleSubmit}>{loading?'Submitting...':'Submit'}</Button>
      </Card.Footer>
    </Card>
  </>
  )
}

export default EmailImport