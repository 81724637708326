import React, { useState, useContext } from 'react'
import {Button, Form, Modal} from 'react-bootstrap';
import Select from 'react-select';
import CustomerContext from '../Context/CustomerContext'

function StatusPopup({popupInfo}) {
  const [viewConfirm, setViewConfirm] = useState(false);
  const [action, setAction] = useState();
  const [message, setMessage] = useState();
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const {
    cookies
  } = useContext(CustomerContext)

    const handleClose = (e) => {
        popupInfo.setShow(false);
        setViewConfirm(false);
    }
    
    const HandleSubmit = (e) =>{
        e.preventDefault();
        let postObj = {
            action:action,
            message:message,
            employeeid:popupInfo.rowData.EmployeeID
        }
        if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/PostCovidMessage?token=${cookies.get('customer').Token}`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postObj)
        })
        .then(res=>{
          popupInfo.setShow(false);
          setViewConfirm(false);
        })
        .catch(err=>{
          console.error(err)
        })
    }
  
    return (
      <>  
        <Modal show={popupInfo.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Compose Message</Modal.Title>
          </Modal.Header>
            <Form onSubmit={HandleSubmit}>
              {viewConfirm ? 
              <Form.Group>
                <Modal.Body>
                  This is a confirmation message Korby wants me to put in.
                </Modal.Body>
                <Modal.Footer>
                  <Button type='submit'>Accept</Button>
                </Modal.Footer>
              </Form.Group>
              :
              <Form.Group> 
                <Modal.Body>
                        <Form.Label>Message</Form.Label>
                        <Form.Control onBlur={(e)=>setMessage(e.target.value)} placeholder='Compose your message'></Form.Control>
                        <Form.Label>Action</Form.Label>
                        <Select onChange={(e)=>setAction(e.value)} options={popupInfo.options}></Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e)=>setViewConfirm(true)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
              </Form.Group>
              }  
            </Form>
        </Modal>
      </>
    );
  }

  export default StatusPopup;