import React, { useContext } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import './Styles/NavMenu.css';
import { CustomerContext } from './Components/Context/CustomerContext';
import { Header } from 'employnet-react-components';
import useFetchGet from './Components/CustomHooks/useFetchGet';

export function NavMenu () {
  const {
    cookies,
    currentPage
  } = useContext(CustomerContext)
  const navigate = useNavigate();
  const {REACT_APP_UTILITIES_API_URL} = process.env;
  const { data: menuOptions } = useFetchGet((cookies.get('customer')&&`${REACT_APP_UTILITIES_API_URL}/getmenu?token=${cookies.get('customer').Token}&appname=Compliance`),cookies)

  function Logout(){
    RemoveAuth().then(()=>{
      navigate('/login')
    })
  }

  const RemoveAuth = async function(){
    cookies.remove('customer', { path:'/' })
    return;
  }

    return (
      <Header className='navbar-container' appName='Compliance' currentPage={currentPage} loggedInUser={cookies.get('customer')} HandleLogout={Logout} MenuOptions={menuOptions&&menuOptions.Tree}  />
      // <header>
      //   <Navbar bg="light" expand="md" className='navbar-container'>
      //       <Navbar.Brand href="/">
      //         <h5>Employnet Compliance {currentPage ? currentPage : null}</h5>
      //       </Navbar.Brand>
      //       <Navbar.Toggle aria-controls="basic-navbar-nav" />
      //       <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
      //           {cookies.get('customer')==null?
      //           <Nav className="me-auto navbar-nav">
      //               <Nav.Link href="/login">Login</Nav.Link>
      //           </Nav>
      //             :
      //             <Nav className="me-auto navbar-nav">
      //               <NavDropdown title="Panels" id="basic-nav-dropdown">
      //                 < NavDropdown.Item href={'/Covid'}>COVID</ NavDropdown.Item>
      //                 < NavDropdown.Item href={'/SHT'}>SHT</ NavDropdown.Item>
      //                 < NavDropdown.Item href={'/Everify'}>Everify</ NavDropdown.Item>
      //                 < NavDropdown.Item href={'/Vendor'}>Vendor</ NavDropdown.Item>
      //               </NavDropdown>
      //               <NavDropdown title="Payroll" id="basic-nav-dropdown">
      //                 < NavDropdown.Item href={'/InvoiceAging'}>Invoice Aging</ NavDropdown.Item>
      //                 < NavDropdown.Item href={'/CheckRequests'}>Check Requests</ NavDropdown.Item>
      //               </NavDropdown>
      //               <Nav.Link href='/Dashboard'>Dashboard</Nav.Link>
      //               <Nav.Link onClick={Logout}>Logout</Nav.Link>
      //             </Nav>
      //           }
      //       </Navbar.Collapse>
      //   </Navbar>
      // </header>
    );
}

export default NavMenu;