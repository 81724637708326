import React, { useEffect, useState } from 'react'
import ConfirmationPopup from './ConfirmationPopup'

function ConfirmationPopupLogic({ show, setShow, emails}) {
    const [readableEmails, setReadableEmails] = useState();
    const [loadView, setLoadView] = useState(false);

    useEffect(()=>{
        if(emails.length>=0){
            if(emails.length===0){
                setReadableEmails([])
            }
            TransformEmails(emails)
        }
    },[emails])

    const TransformEmails = (emails) => { 
        setReadableEmails(emails.join('\r\n'))
        setLoadView(true)
    }


  return (
      <>
      {loadView&&
        <ConfirmationPopup
            show={show}
            setShow={setShow}
            emails={readableEmails}
        ></ConfirmationPopup>
      }
      </>
  )
}

export default ConfirmationPopupLogic