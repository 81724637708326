import React from 'react'
import { Accordion, Button, Row, Modal, Spinner } from 'react-bootstrap'
import UpdateBackgroundPopupLogic from '../UpdateBackgroundPopup copy/UpdateBackgroundPopupLogic'
import UpdateDrugTestPopupLogic from '../UpdateDrugTestPopup/UpdateDrugTestPopupLogic'
import UpdateEverifyPopupLogic from '../UpdateEverifyPopup/UpdateEverifyPopupLogic'

function CandidateInfoPopup({ openEverifyPopup, setOpenEverifyPopup, openInNewTab, employeeDetails, openDrugTestPopup, setOpenDrugTestPopup, openBackgroundPopup, setOpenBackgroundPopup, candidateInfo, open, setOpen }) {
  return (
      <>
    {openDrugTestPopup&&<UpdateDrugTestPopupLogic candidateInfo={candidateInfo} show={openDrugTestPopup} setShow={setOpenDrugTestPopup} />}
    {openBackgroundPopup&&<UpdateBackgroundPopupLogic formInfo={employeeDetails.backgroundStatus} candidateInfo={candidateInfo} show={openBackgroundPopup} setShow={setOpenBackgroundPopup} />}
    {openEverifyPopup&&<UpdateEverifyPopupLogic candidateInfo={candidateInfo} show={openEverifyPopup} setShow={setOpenEverifyPopup} />}
    <Modal show={open} onHide={setOpen}>
        <Modal.Header closeButton>
            <h3>{candidateInfo.FirstName+' '+candidateInfo.LastName}</h3>
        </Modal.Header>
        <Modal.Body>
        {(employeeDetails&&candidateInfo)?
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Contact Info</Accordion.Header>
                    <Accordion.Body>
                        <Button onClick={(e)=>openInNewTab(`https://beyond.ontempworks.com/employee/${employeeDetails.docStatus.employeeid}`)} className='full-width margin-bottom'>Open in Beyond</Button>
                        <Row><p>Name: <b>{employeeDetails.docStatus.FirstName+' '+employeeDetails.docStatus.LastName}</b></p></Row>
                        <Row><p>EmployeeID: <b>{employeeDetails.docStatus.employeeid}</b></p></Row>
                        <Row><p>Last Pay Date: <b>{employeeDetails.docStatus.LastPayDate&&employeeDetails.docStatus.LastPayDate.split('T')[0]}</b></p></Row>
                        <Row onClick={(e)=>window.open('tel:'+employeeDetails.docStatus.employeecellphone)}><p>Phone: <b className='linked-cell'>{employeeDetails.docStatus.employeecellphone?employeeDetails.docStatus.employeecellphone:employeeDetails.docStatus.employeehomephone}</b></p></Row>
                        <Row onClick={(e)=>window.open('mailto:'+employeeDetails.docStatus.employeeemail)}><p>Email: <b className='linked-cell'>{employeeDetails.docStatus.employeeemail}</b></p></Row>
                        <Row><p>Customer: <b>{employeeDetails.docStatus.CustName}</b></p></Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>E-Verified</Accordion.Header>
                    <Accordion.Body>
                        <Row><p>Everified Status: <b>{employeeDetails.everify.IntepretedStatus}</b></p></Row>
                        <Button onClick={(e)=>setOpenEverifyPopup(true)}>Enter E-Verify</Button>
                        {(candidateInfo.Everified==='Yes')&&
                        <>
                            <Row><p>Case Status: <b>{employeeDetails.CaseStatus}</b></p></Row>
                            <Row><p>Case Eligibility Statement: <b>{employeeDetails.CaseEligibilityStatement}</b></p></Row>
                        </>
                        }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Background</Accordion.Header>
                    <Accordion.Body>
                        <Row><p>Background Status: <b>{employeeDetails.backgroundStatus.StatusMessage}</b></p></Row>
                        {employeeDetails.backgroundStatus.ReportLocation?
                        <Row className='margin-bottom'><Button onClick={(e)=>openInNewTab(employeeDetails.backgroundStatus.ReportLocation)}>Open Background Report</Button></Row>
                        :
                        <>
                        {(!employeeDetails.backgroundStatus.StatusMessage!='NA')&&
                            <Row><p>This background check is more than 30 days old, so you will need to visit the PeopleG2 website to view it.</p><Button className='margin-bottom' onClick={(e)=>openInNewTab('https://access.peopleg2.com/')}>Log in to PeopleG2</Button></Row>
                        }
                        </>
                        }
                        {(employeeDetails.backgroundStatus.DateCreated&&(employeeDetails.backgroundStatus.StatusMessage==='Needs Review'))&&
                        <Row><Button onClick={(e)=>setOpenBackgroundPopup(true)}>Approve/Reject Background</Button></Row>
                        }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Drug Test</Accordion.Header>
                    <Accordion.Body>
                        <Row><p>Drug Test Status: <b>{employeeDetails.drugTest.DrugCompliance}</b></p></Row>
                        <Button onClick={(e)=>setOpenDrugTestPopup(true)}>Enter Drug Test</Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        :
        <Spinner animation='border' />
        }
        </Modal.Body>
        <Modal.Footer>
            <Button className='full-width' variant='secondary' onClick={(e)=>setOpen(false)}>Close</Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default CandidateInfoPopup