import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from '../Context/CustomerContext';
import CandidateInfoPopup from './CandidateInfoPopup'

function CandidateInfoPopupLogic({ candidateInfo, open, setOpen }) {
    const [openBackgroundPopup, setOpenBackgroundPopup] = useState(false);
    const [openDrugTestPopup, setOpenDrugTestPopup] = useState(false);
    const [employeeDetails, setEmployeeDetails] = useState();
    const [openEverifyPopup, setOpenEverifyPopup] = useState();
    const {cookies} = useContext(CustomerContext)
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

    useEffect(()=>{
      const GetEmployeeDetail = (token, employeeid) => { 
        if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/getemployeedetail?token=${token}&employeeid=${employeeid}`)
        .then(res=>{
            if(res.status===200){
                return res.json()
            }else if(res.status===401){
                cookies.remove('customer')
                window.location.reload()
            }else if(!res.ok){
                throw Error(res.statusText)
            }
        })
        .then(data=>{
            setEmployeeDetails(data)
        })
        .catch(error=>{
            console.error(error)
            window.alert(error)
        })
       }
       if(!employeeDetails){
         GetEmployeeDetail(cookies.get('customer').Token, candidateInfo.employeeid)
       }
    },[employeeDetails])

    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
    
  return (
    <CandidateInfoPopup
      open={open}
      setOpen={setOpen}
      candidateInfo={candidateInfo}
      openBackgroundPopup={openBackgroundPopup}
      setOpenBackgroundPopup={setOpenBackgroundPopup}
      openDrugTestPopup={openDrugTestPopup}
      setOpenDrugTestPopup={setOpenDrugTestPopup}
      openInNewTab={openInNewTab}
      employeeDetails={employeeDetails}
      openEverifyPopup={openEverifyPopup}
      setOpenEverifyPopup={setOpenEverifyPopup}
    ></CandidateInfoPopup>
  )
}

export default CandidateInfoPopupLogic