import React, { Component } from 'react';
import CacheBuster from './CacheBuster';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { CustomerProvider } from './Components/Context/CustomerContext';
import { CookiesProvider } from 'react-cookie';
import LoginLogic from './Components/Login/LoginLogic';
import FourZeroFour from './Components/FourZeroFour';
import Privacy from './Privacy';
import Layout from './Layout';
import './Styles/App.css'
import PrivateRoute from './Components/Auth/PrivateRoute';
import PublicRoute from './Components/Auth/PublicRoute'
import NewDocStatus from './Components/Dashboard/NewDocStatus';
import Covid from './Components/Covid/Covid';
import SHT from './Components/SHT/SHT';
import Everify from './Components/Everify/Everify';
import EmailImportLogic from './Components/EmailImport/EmailImportLogic';
import Vendor from './Components/Vendor/Vendor';
import InvoiceAging from './Components/Payroll/InvoiceAging/InvoiceAging';
import CheckRequest from './Components/Payroll/CheckRequest/CheckRequest';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; 
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

class App extends Component {

  render(){
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
            return (
              <CookiesProvider>
                <CustomerProvider>
                  <BrowserRouter>
                    <Layout>  
                      <Routes>
                        <Route exact path='/' element={<PublicRoute><LoginLogic /></PublicRoute>} />
                        <Route path='/login' element={<PublicRoute><LoginLogic /></PublicRoute>} />
                        <Route path='/Privacy' element={<Privacy />} />
                        <Route path='/SHT' element={<PrivateRoute><SHT /></PrivateRoute>} />
                        <Route path='/Dashboard' element={<PrivateRoute><NewDocStatus /></PrivateRoute>} />
                        <Route path='/Covid' element={<PrivateRoute><Covid /></PrivateRoute>} />
                        <Route path='/InvoiceAging' element={<PrivateRoute><InvoiceAging /></PrivateRoute>} />
                        <Route path='/CheckRequest' element={<PrivateRoute><CheckRequest /></PrivateRoute>} />
                        <Route path='/Everify' element={<PrivateRoute><Everify /></PrivateRoute>} />
                        <Route path='/Vendor' element={<PrivateRoute><Vendor /></PrivateRoute>} />
                        <Route path='/tools/disablecontacts' element={<PrivateRoute><EmailImportLogic /></PrivateRoute>} />
                        <Route path='*' element={<PrivateRoute><FourZeroFour /></PrivateRoute>} />
                      </Routes>
                    </Layout>
                  </BrowserRouter>
                </CustomerProvider>
              </CookiesProvider>
            )
        }}
      </CacheBuster>
    )
  }
}

export default App;
