import React from "react";
import {Form, Button, Col, Row, InputGroup, Container, Modal} from "react-bootstrap";
import ButtonMailto from "./EmailButton";
import Select, { makeAnimated } from 'react-select'

export default function ContactListView({
    mailto,
    subject,
    body,
    HandleSubmit,
    show,
    onHide,
    closeTable,
    contacts,
    emailList,
    HandleEmailChange,
    showTable,
    selectedRowData
}){
    return (
        <div>
        <Modal dialogClassName="modal-70w" show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <h3>Add Contacts</h3>
                </Modal.Header>
                <Modal.Body>
                <p className="small-info-font ">* Greyed out names have no email in Tempworks</p>
                    <Container>
                    
                        <Select
                            closeMenuOnSelect={false}
                            options={emailList}
                            placeholder='Select Contacts to Email'
                            isMulti
                            onChange={(e)=>HandleEmailChange(e)
                            }
                        />     
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="link-button-next" onClick={()=>HandleSubmit()}>Next</Button>
                </Modal.Footer>
            </Modal> 
        </div>
    );
};