import React from "react";
import './InvoiceAging.css';
import { AgGridReact } from "ag-grid-react";
import { Button, Offcanvas } from 'react-bootstrap';
import Select from 'react-select';
import CustomerInfoPopup from "./CustomerInfoPopup/CustomerInfoPopup";
import ContactList from "../../CustomComponents/Emailer/ContactList";

export default function InvoiceAgingView({
    company,
    setCompany,
    pastDue,
    setPastDue,
    rowData,
    onGridReady,
    colDefs,
    defaultColDef,
    pinnedBottomRowData,
    onFilterChanged,
    showSidebar,
    setShowSidebar,
    customers,
    selectedCustomer,
    HandleCustomerChange,
    selectedCompany,
    HandleCompanyChange,
    selectedPastDue,
    HandleDateChange,
    HandleSubmit,
    ExportToCSV,
    HandleShowFilters,
    rowSelection,
    payTerms,
    selectedPayTerms,
    HandleTermsChange,
    OpenInfoCard,
    contactPopupIsOpen,
    setContactPopupIsOpen,
    CloseCard,
    selectedCustomerID,
    department,
    invoiceNumber,
    invoiceDate,
    balanceAmount,
    EmailSelectedInvoices,
    selectedRowData,
    showMailer,
    setShowMailer,
    closeMailer,
    onSelectionChanged,
    modalController,
    setModalController,
    // userIDCounter,
    // setUserIDCounter
}){

    return(
        <div>
            <Button className="link-button" onClick={()=>HandleShowFilters()}>Show Filters</Button>
            <Button className="link-button" onClick={()=>ExportToCSV(true)}>Export to CSV</Button>
            <Button className="link-button" onClick={()=>EmailSelectedInvoices()}>Email Invoices</Button>
            {/* <Button className="link-button" onClick={()=>setUserIDCounter(userIDCounter + 1)}>Toggle View</Button> */}
            
            <Offcanvas show={showSidebar} onHide={()=>setShowSidebar(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filters</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Select options={company} value={selectedCompany} placeholder='Company' onChange={(e)=>HandleCompanyChange(e)}/>
                    <br />
                    <Select options={customers} value={selectedCustomer} placeholder='Customers' onChange={(e)=>HandleCustomerChange(e)}/>
                    <br/>
                    <Select options={pastDue} value={selectedPastDue} placeholder='Days Past Due' onChange={(e)=>HandleDateChange(e)}/>
                    <br/>
                    <Select options={payTerms} value={selectedPayTerms} placeholder='Payment Terms' onChange={(e)=>HandleTermsChange(e)}/>
                    <br/>

                    <Button className="link-button" onClick={()=>HandleSubmit()}>Apply Filters</Button>
                </Offcanvas.Body>
            </Offcanvas>

            {contactPopupIsOpen&&<CustomerInfoPopup
                selectedCustomerID={selectedCustomerID}
                show={contactPopupIsOpen} 
                onHide={CloseCard}/>}
                
            {showMailer&&<ContactList
                selectedRowData={selectedRowData}
                selectedCustomerID={selectedCustomerID}
                showMailer={showMailer}
                setShowMailer={setShowMailer}
                onHide={closeMailer}
                modalController={modalController}
                setModalController={setModalController}/>}

            <div className="ag-theme-balham" style={{width: '100%', height: '80vh'}}>
                <AgGridReact
                    multiSortKey={'ctrl'}
                    suppressExcelExport={true}
                    rowData={rowData}
                    onGridReady={onGridReady}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    pinnedBottomRowData={pinnedBottomRowData}
                    onFilterChanged={onFilterChanged}
                    onCellDoubleClicked={OpenInfoCard}
                    rowSelection={'multiple'}
                />
            </div> 

        </div>
               
    );
};