import React, {useState, useEffect} from "react";

export default function TableBuilder({selectedRowData}){
    // populate tableBody table based on row data for selected inovices
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
   

    const FormatUSD = (params) =>{
        return `${formatter.format(params)}`
      };
  
      const FormatDate = (params) =>{
        // return date without time stamp and 'T'
          let fixedDate = params.split('T')[0]
          return fixedDate
      };

    let invoiceSum = 0;

    return(
        <table id="invoiceTable" border="1" cellPadding="5" cellSpacing="1" width="100%">
            <thead>
                <tr bgcolor='#8FAAC4'>
                    <th>Client Name</th>
                    <th>Department Name</th>
                    <th>Payment Terms</th>
                    <th>Invoice Number</th>
                    <th>Invoice Date</th>
                    <th>Days Past Due</th>
                    <th>Balance</th>
                </tr>
            </thead>
            <tbody>
            {selectedRowData.map((rowData)=>{
            return (          
                    <tr>
                        <td>{rowData.customerName}</td>
                        <td>{rowData.DepartmentName}</td>
                        <td>{rowData.paymentTerms}</td>
                        <td>{rowData.invoiceNumber}</td>
                        <td>{FormatDate(rowData.invoiceDate)}</td>
                        <td>{rowData.PastDueInDays}</td>
                        <td>{FormatUSD(rowData.BalanceAmount)}</td>
                        <td style={{display: "none"}}>{invoiceSum+=rowData.BalanceAmount}</td>
                    </tr>
                    );
            })}
                <tr bgcolor='#8FAAC4'>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{FormatUSD(invoiceSum)}</td>
                </tr>
            </tbody>
        </table>
    );
};

