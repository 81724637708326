import React, { useContext, useEffect, useState } from 'react'
import EmailImport from './EmailImport'
import CustomerContext from '../Context/CustomerContext'

function EmailImportLogic() {
    const [message, setMessage] = useState();
    const [postObj, setPostObj] = useState();
    const [loading, setLoading] = useState(false);
    const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] = useState(false);
    const [nonDeletedEmails, setNonDeletedEmails] = useState();
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const {
        cookies,
        setCurrentPage,
    } = useContext(CustomerContext);

    useEffect(()=>{
        setCurrentPage('Disable Contacts');
// eslint-disable-next-line
    },[])

    const HandleChange = (e) => { 
        setMessage(null)
        const rows = e.target.value.split("\n")
        const postObj = {emails:[]}
        postObj.emails = rows.filter(row=>row.length>0).map(row=>{
            return {'email':row}
        })
        setPostObj(postObj)
    }

    const HandleSubmit = (e) => { 
        setLoading(true);
        setMessage({variant:'info', message:'Removing emails, this may take several minutes'})
        if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/PostRemoveEmails?token=${cookies.get('customer').Token}`,{
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        body: JSON.stringify(postObj)
        })
        .then(res=>{
            if(res.status===200){
                return res.json();
            }else if(res.status===401){
                cookies.remove('customer')
                window.location.reload()
            }else if(!res.ok){
                throw res.json()
            }
        })
        .then(data=>{
            setConfirmationPopupIsOpen(true);
            setNonDeletedEmails(data)
            setLoading(false);
            setMessage(null)
        })
        .catch(error=>{
            error.then(error=>{
                setLoading(false);
                setMessage({variant:'warning',message:error})
                console.error(error)
                window.alert(error)
            })
        })
     }
    

  return (
    <EmailImport
        HandleChange={HandleChange}
        HandleSubmit={HandleSubmit}
        loading={loading}
        message={message}
        confirmationPopupIsOpen={confirmationPopupIsOpen}
        setConfirmationPopupIsOpen={setConfirmationPopupIsOpen}
        nonDeletedEmails={nonDeletedEmails}
    ></EmailImport>
  )
}

export default EmailImportLogic