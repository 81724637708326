import React from "react";
import {Button, Container, Modal} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import Select, { makeAnimated } from 'react-select';
import TableBuilder from "../../../CustomComponents/TableBuilder";

export default function TablePopupView({
    showTable,
    closeTable,
    selectedRowData,
    HandleCopy,
    CopyHTML
}){
    return(
        <div>
            <Modal dialogClassName="modal-70w" show={showTable} onHide={closeTable}>
                <Modal.Header closeButton>
                    <h3>Invoice Table</h3>
                </Modal.Header> 
                <Modal.Body>
                    <Button className="link-button" onClick={()=>HandleCopy()}>Copy Table</Button>
                    {/* <Button className="link-button" onClick={()=>CopyHTML()}>Copy HTML</Button> */}
                    <Container>
                        <div>
                            <TableBuilder
                                selectedRowData={selectedRowData}
                            />
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="link-button-submit" onClick={()=>HandleCopy()}>Copy Table</Button>
                </Modal.Footer>
            </Modal>
        </div>                             
    );
};