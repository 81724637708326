import React, { useState } from "react";
import CheckRequestView from "./CheckRequestView";
import { CSVLink, CSVDownload } from "react-csv";
import ButtonMailto from "../../CustomComponents/Emailer/EmailButton";

export default function CheckRequest(){
    const [weDate, setWeDate] = useState("");
    const [empName, setEmpName] = useState("");
    const [custName, setCustName] = useState("")
    const [eeID, setEeID] = useState("");
    const [asnID, setAsnID] = useState("");
    const [requestID, setRequestID] = useState("");

    const [regHours, setRegHours] = useState("");
    const [otHours, setOTHours] = useState("");
    const [dtHours, setDTHours] = useState("");

    const [hoursType, setHoursType] = useState("");
    const [hoursNote, setHoursNote] = useState("");
    const [payStatus, setPayStatus] = useState("");
    const [billStatus, setBillStatus] = useState("");
  
    const [csvData, setCsvData] = useState([
      ["Week Ending Date", "Pay Code", "Assignment ID", "Employee ID", "Reg Hours", "OT Hours", "DT Hours",
        "Units", "Unit Pay Rate", "Unit BIll Rate", "Exp Reim Adj Pay Amount", "Exp Reim Adj Bill Amount", "Exp Reim Adj Invoice Text",
        "Per Diem Adj Pay Amount", "Per Diem Adj Bill Amount", "Car Allowance Pay", "Car Allowance Bill",
        "Per Diem Adj Invoice Text", "Invoice Text", "Cost Center", "Vend Inv Number"]
    ]);
    
    const [body, setBody] = useState(
      [["\n"]]
    );

    let emailString = "";
  
    const handleSubmit = (e)=>{
      e.preventDefault();

      let newArray = [...csvData];
      newArray[newArray.length]=[weDate, "Reg", asnID, eeID, regHours, otHours, dtHours];
      setCsvData(newArray);

      const form = e.currentTarget;

      let newBody = [...body];
      newBody[newBody.length]=[`
      WE Date: ${weDate} \n
      Customer Name: ${custName} \n
      Employee Name: ${empName} \n
      Employee ID: ${eeID} \n
      Assignment ID: ${asnID} \n
      Request Type: ${requestID} \n
      Reg Hours: ${regHours} \n
      OT Hours: ${otHours} \n
      DT Hours: ${dtHours} \n
      Pay Type: ${payStatus} \n
      Bill Type: ${billStatus} \n\n
      `];
      emailString = newBody.join('').toString();
      setBody(emailString);
      
      setWeDate("");
      setCustName("");
      setEmpName("");
      setEeID("");
      setAsnID("");
      setRequestID("");
      setRegHours("");
      setOTHours("");
      setDTHours("");
      setPayStatus("");
      setBillStatus("");
    };

    return (
        <CheckRequestView
            weDate={weDate}
            setWeDate={setWeDate}
            custName={custName}
            setCustName={setCustName}
            empName={empName}
            setEmpName={setEmpName}
            eeID={eeID}
            setEeID={setEeID}
            asnID={asnID}
            setAsnID={setAsnID}
            requestID={requestID}
            setRequestID={setRequestID}
            regHours={regHours}
            setRegHours={setRegHours}
            otHours={otHours}
            setOTHours={setOTHours}
            dtHours={dtHours}
            setDTHours={setDTHours}
            billStatus={billStatus}
            setBillStatus={setBillStatus}
            payStatus={payStatus}
            setPayStatus={setPayStatus}
            csvData={csvData}
            setCsvData={setCsvData}
            body={body}
            setBody={setBody}
            handleSubmit={handleSubmit}
            emailString={emailString}  
            ></CheckRequestView>
    );
}