import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'
import Select from 'react-select';
import AllBranchesLogic from '../AllBranches/AllBranchesLogic';

function AdvancedSearch({ today, HandleChange, HandleSubmission, orderTypeOptions, performanceCodeOptions, dateFilterOptions }){

    return(
            <Form onSubmit={HandleSubmission} className='advanced-search'>
                <Row>
                    <Col>
                        <AllBranchesLogic className='full-width'></AllBranchesLogic>
                        
                        <Form.Label>Date Filter:</Form.Label>
                        <Select onChange={(e)=>HandleChange(e,'DateFilter')} name='Date Filter' defaultValue={{value:'ActiveAsgWithinSpecifiedRange',label:'Active Assignment Within Specified Range'}} options={dateFilterOptions}></Select>
                        
                        <Form.Label>Start Date:</Form.Label>
                        <Form.Control onChange={(e)=>HandleChange(e,'StartDate')} defaultValue={today} name='Start Date' type='date'></Form.Control>
                    </Col>
                    <Col>
                        <Form.Label>Order Type:</Form.Label>
                        <Select onChange={(e)=>HandleChange(e,'OrderType')} name='Order Type' options={orderTypeOptions} defaultValue={orderTypeOptions[0]}></Select>

                        <Form.Label>Performance Code:</Form.Label>
                        <Select defaultValue={{value:null,label:'All'}} onChange={(e)=>HandleChange(e,'PerformanceCode')} name='Performance Code' options={performanceCodeOptions}></Select>
                        
                        
                        <Form.Label>End Date:</Form.Label>
                        <Form.Control onChange={(e)=>HandleChange(e,'EndDate')} defaultValue={today} name='End Date' type='date'></Form.Control>
                        
                        <Button type='submit' className="full-width">Search</Button>
                    </Col> 
                </Row>
            </Form>
    )
}

export default AdvancedSearch;