import React, { useState, useEffect, useContext } from 'react'
import Grid from './Grid'
import CustomerContext from '../Context/CustomerContext'
import { useNavigate } from 'react-router-dom'

function GridLogic({SortData, defaultColDefs, currentGrid, currentPage, apiUrl, HandleClick, customerColumnDefs, popupInfo }){
    const [gridApi, setGridApi] = useState();
    const [gridData, setGridData] = useState();
    const [advancedView, setAdvancedView] = useState(false);
    const [loading, setLoading] = useState({loading:true,message:'Loading...'});
    const [currentFilter, setCurrentFilter] = useState('all')
    const [data, setData] = useState();
    const [columnDefs, setColumnDefs] = useState(defaultColDefs);
    const [infoPopupIsOpen, setInfoPopupIsOpen] = useState(false);
    const [candidateInfo, setCandidateInfo] = useState();
    const navigate = useNavigate();
    const {
        cookies,
        selectedBranch,
        setCurrentPage,
        setSelectedBranch
    } = useContext(CustomerContext)

    useEffect(()=>{
        setCurrentPage(currentPage)
        // eslint-disable-next-line
        if(currentGrid==='Vendor'){
            // I have to call this to call the API to get the data for Vendors, since Vendors doesn't use a branch selector
            // Typically when branch is selected, that's when the API is triggered because the setSelectedBranch is called
            setSelectedBranch(1)
        }
    },[])
    
    useEffect(()=>{
        if(selectedBranch&&!advancedView){
            GetDataFromDB(apiUrl, selectedBranch.BranchName);
        }
        // eslint-disable-next-line
    },[selectedBranch])
    
    function GetDataFromDB(apiUrl, branch){
        setLoading({loading:true,message:'Requesting data...'});
        if(branch){
            apiUrl = (apiUrl+`&BranchName=${branch}`)
        }
        if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(apiUrl)
        .then(res=>{
            if(res.status===200){
                setLoading({loading:true, message:'Compiling data...'})
                res.json()   
                .then(data=>{
                    setData(data)
                    if(currentFilter==='tobecalled'){
                        FilterToBeCalled();
                        setLoading({loading:false,message:''});
                    }else{
                        setGridData(data);
                        setLoading({loading:false,message:''});
                    }
                })
            }else if(res.status===401){
                cookies.remove('customer',{path:'/'})
                navigate('/')
            }else{
                setLoading({loading:true,message:'Failed to get data, please refresh the page and try agian.'})
            }
        })
        .catch(err=>console.error(err))
    }

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

    function FilterToBeCalled(){
        setCurrentFilter('tobecalled')
        let filteredData = SortData(data)
        setColumnDefs(defaultColDefs)
        setGridData(filteredData)
    }

    const OpenInfoPopup = (event) => { 
        setCandidateInfo(event.data)
        let header = event.colDef.headerName;
        if(header==='SHT'||header==='I9'||header==='W4'||header==='E-Verified'||header==='Background'||header==='Drug Test'){
            setInfoPopupIsOpen(true);
        }
        HandleClick(event);
     }  

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    }

    function ChangeView(e){
        setAdvancedView(!advancedView)
    }

    function OnGridReady(params){
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
    }

    function onNewColumnsLoaded(params){
        params.columnApi.autoSizeAllColumns();
    }

    function FilterAll(){
        setCurrentFilter('all')
        setGridData(data)
        setColumnDefs(defaultColDefs)
    }

    function FilterCustomer(){
        setCurrentFilter('customer')
        setGridData(data)
        setColumnDefs(customerColumnDefs)
    }

    return(
            <Grid
                gridData={gridData}
                onBtnExport={onBtnExport}
                advancedView={advancedView}
                setGridData={setGridData}
                setLoading={setLoading}
                loading={loading}
                ChangeView={ChangeView}
                columnDefs={columnDefs}
                OnGridReady={OnGridReady}
                defaultColDef={defaultColDef}
                FilterToBeCalled={FilterToBeCalled}
                FilterAll={FilterAll}
                FilterCustomer={FilterCustomer}
                currentGrid={currentGrid}
                popupInfo={popupInfo}
                infoPopupIsOpen={infoPopupIsOpen}
                setInfoPopupIsOpen={setInfoPopupIsOpen}
                OpenInfoPopup={OpenInfoPopup}
                candidateInfo={candidateInfo}
                onNewColumnsLoaded={onNewColumnsLoaded}
            ></Grid>
    )
}

export default GridLogic;