import React, { useState, useContext, useEffect } from 'react'
import Login from './Login'
import CustomerContext from '../Context/CustomerContext'
import { useNavigate } from 'react-router-dom';

const LoginLogic = () =>{
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);
    const {
        cookies,
        setCurrentPage
    } = useContext(CustomerContext)
    const navigate = useNavigate();

    useEffect(()=>{
        setError(null)
    },[password,username])

    useEffect(()=>{
        setCurrentPage(null)
    // eslint-disable-next-line
    },[])

    function ValidateUser(e){
        e.preventDefault();
        setLoading(true)
        fetch(`${REACT_APP_API_URL}/LoginUser?UserName=${username}&Password=${password}`)
        .then(res => {
            if(res.status===200){
                return res.json().then(data=>{
                    cookies.set('customer', data, {expires:new Date(data.ExpirationDate), path:'/'})
                    setLoading(false)
                    return true;
                })
            }else if(res.status===404){
                setError({variant:'danger',message:'Invalid login. Please try again or contact your system administrator.'})
                setLoading(false)
                return false;
            }else{
                return Error(res)
            }
        })
        .then(res=>{
            if(res){
                setLoading(false)
                navigate('/dashboard')
            }
        })
        .catch(err=>{
            setLoading(false)
            setError({variant:'danger',message:'Invalid login. Please try again or contact your system administrator.'})
            console.error('error',err)
            window.alert('Invalid login, try again')
        })
    }

    return (
        <Login
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            ValidateUser={ValidateUser}
            error={error}
            loading={loading}
        ></Login>
    )
}

export default LoginLogic;