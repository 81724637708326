import React, { useState } from 'react';
import Cookies from 'universal-cookie';

export const CustomerContext = React.createContext();

export function CustomerProvider({ children }){

    const cookies = new Cookies();
    const [user, setUser] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [currentPage, setCurrentPage] = useState();


    return(
        <CustomerContext.Provider value={
            {   
                user,
                setUser,
                cookies,
                selectedBranch,
                setSelectedBranch,
                currentPage,
                setCurrentPage
            }
        }>
        {children}
    </CustomerContext.Provider>
)
}

export default CustomerContext;