import React from 'react'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'

function UpdateEverifyPopup({ submitted, drugTestOptions, HandleCreateOption, error, HandleChange, setIsApproved, show, setShow, loading, HandleSubmit }) {
  return (
    <Modal show={show} onHide={setShow}>
      {submitted?
      <>
      <Modal.Header closeButton>
          <h3>Enter E-Verify</h3>
      </Modal.Header>
      <Modal.Body className='text-center'>
          <h5>Request has been submitted succesfully!</h5>
          <p><b>It takes up to 15 minutes for the information that was just submitted to sync.<br/> Please do not put this request in again until 15 minutes have passed and you have refreshed the grid.<br/> Thank you.</b></p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={(e)=>setShow(false)} variant='secondary'>Close</Button>
      </Modal.Footer>
      </>
      :
      <>   
      <Modal.Header closeButton>
          <h3>Enter E-Verify</h3>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Label>Status<span>*</span></Form.Label>
          <Select options={[{value:null,label:'Started but incomplete'},{value:false,label:'Complete - Not Authorized'},{value:true,label:'Complete - Authorized'}]} onChange={(e)=>setIsApproved(e.value)} />

          <Form.Label>Date of Everify<span>*</span> </Form.Label>
          <Form.Control onChange={(e)=>HandleChange(e,'Date')} type='date' />

          <Form.Label>Case Number<span>*</span></Form.Label>
          <Form.Control onChange={(e)=>HandleChange(e,'Everify')} />

          <Form.Label>Notes</Form.Label>
          <Form.Control onChange={(e)=>HandleChange(e,'Notes')} />

          {error&&
          <Alert variant='warning'>{error}</Alert>
          }
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={HandleSubmit}>{loading?'Submitting...':'Submit'}</Button>
        <Button onClick={(e)=>setShow(false)} variant='secondary'>Close</Button>
      </Modal.Footer>
      </>
      }
    </Modal>
  )
}

export default UpdateEverifyPopup