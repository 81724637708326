import React from 'react';
import { Card } from 'react-bootstrap';

const NoMatch = () =>{
    return(
      <Card>
        <h1 className="text-center">404 Page Not Found</h1>
        <Card.Body className="text-center">The page you are looking for does not exist.<br/> If you feel you have found this page in error, either email us at <a href="mailto: ewminfo@employnet.com">ewminfo@employnet.com</a> or click on the Chat box in at the bottom of your screen.</Card.Body>
      </Card>
    )
}

export default NoMatch;