import React from 'react'
import Select from 'react-select';
import { Row, Form } from 'react-bootstrap';

function AllBranchesLogic({ allBranches, SetSelectedBranch, selectedBranch}){
    
    return(
        <div className="all-branches-wrapper">
            {allBranches ?
                <Row>
                    <Select options={allBranches} defaultValue={{value:selectedBranch, label:selectedBranch.BranchName}} onChange={SetSelectedBranch}></Select>
                </Row>
            : null}
        </div>
    )
}

export default AllBranchesLogic;