import React, { useContext, useState } from 'react'
import UpdateDrugTestPopup from './UpdateBackgroundPopup'
import CustomerContext from '../Context/CustomerContext'

function UpdateBackgroundPopupLogic({ formInfo, show, setShow, candidateInfo }) {
    const [isApproved, setIsApproved] = useState();
    const [notes, setNotes] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [submitted, setSubmitted] = useState(false);
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const {cookies} = useContext(CustomerContext)

    const HandleSubmit = (event) => { 
      let postObj = {
        EmployeeID: candidateInfo.employeeid,
        IsApproved: isApproved,
        Type: formInfo.Summary,
        Date: formInfo.DateCreated,
        Notes: notes
      }
      if(ValidatePostObj(postObj)){
        setLoading(true);
        PostBackgroundResponse(cookies.get('customer').Token, postObj)
      }
     }

    const ValidatePostObj = (postObj) => { 
        if(isApproved==null){
          setError('Please select an Approved option')
          return false;
        }else{
          return true;
        }
     }

    const PostBackgroundResponse = (token, postObj) => { 
      if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/PostBackgroundResponse?token=${token}`,{
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        body: JSON.stringify(postObj)
        })
        .then(res=>{
            if(res.status===200){
              setLoading(false)
              setSubmitted(true)
            }else if(res.status===401){
                cookies.remove('customer')
                window.location.reload()
            }else if(!res.ok){
                throw Error(res.statusText)
            }
        })
        .catch(error=>{
            setLoading(false);
            console.error(error)
            window.alert(error)
        })
     }
  return (
    <UpdateDrugTestPopup
      show={show}
      setShow={setShow}
      setNotes={setNotes}
      setIsApproved={setIsApproved}
      loading={loading}
      HandleSubmit={HandleSubmit}
      error={error}
      formInfo={formInfo}
      submitted={submitted}
    ></UpdateDrugTestPopup>
  )
}

export default UpdateBackgroundPopupLogic