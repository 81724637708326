import React, {useState, useContext, useEffect} from "react";
import ContactListView from "./ContactListView";
import ButtonMailto from "./EmailButton";
import useFetchGet from "../../CustomHooks/useFetchGet";
import CustomerContext from "../../Context/CustomerContext";
import TablePopupView from "../../Payroll/InvoiceAging/CustomerInfoPopup/TablePopupView";
import { AgGridReact } from "ag-grid-react";

export default function ContactList({
    selectedRowData,
    selectedCustomerID,
    showMailer,
    setShowMailer,
    onHide,
    modalController,
    setModalController
}){
    // bring up menu to select contacts to add to email based on contacts from CustID
    const { cookies } = useContext(CustomerContext);
    const { REACT_APP_ORDER_REQUEST_API_URL } = process.env;
    const { data: contacts } = useFetchGet(`${REACT_APP_ORDER_REQUEST_API_URL}/getcustomercontacts?customerID=${selectedCustomerID}&token=${cookies.get('customer').Token}`, cookies);
    // const [contacts, setContacts] = useState()
    const [emailList, setEmailList] = useState();
    // add as To, ad as cc, add as bcc buttons add contacts to email
    const [mailto, setMailto] = useState([]);
    //const [cc, setCC] = useState();
    //const [bcc, setBcc] = useState();
    const [subject, setSubject] = useState();
    const [showTable, setShowTable] = useState(false);
    const abortController = new AbortController();


    // useEffect(()=>{
    //     if(unfilteredContacts){
    //         if(contacts){
    //             // compare 
    //         }else{
    //             setContacts(unfilteredContacts)
    //         }
    //     }
    // }.[unfilteredContacts])

    let emailListString = "";

    const [tableRowData, setTableRowData] = useState();
    const [gridApi, setGridApi] = useState();
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const [defaultColDef] = useState ({
        sortable: true,
        resizable: true,
        filter: true,       
    });

    const [tableColDefs] = useState([
        {field:'customerName', headerName: 'Client Name'},
        {field: 'DepartmentName', headerName: 'Deparment'},
        {field: 'paymentTerms'},
        {field: 'invoiceNumber'},
        {field: 'invoiceDate', valueFormatter: (data)=>FormatDate(data)},
        {field: 'PastDueInDays'},
        {field: 'BalanceAmount', valueFormatter: (data)=>FormatUSD(data)},
    ]);

    const onGridReady = (params) => { 
        setGridApi(params.api);
        params.columnApi.autoSizeAllColumns();
      };
  
    //display table from rows selected in invoice aging
    useEffect(()=>{
        if(selectedRowData){
            setTableRowData(selectedRowData)
        };
        return ()=>{
            abortController.abort();
        };
    },[selectedRowData]);

    // for formatting dollar values
    const FormatUSD = (data) =>{
        return `${formatter.format(data.value)}`
      };
  
      const FormatDate = (data) =>{
        // return date without time stamp and 'T'
        if(data.value){
          let fixedDate = data.value.split('T')[0]
          return fixedDate
        }else{
          return ''
        }  
      };

    //setting up the filter
    useEffect(()=>{
        if(contacts){
            let formattedEmails = contacts.data.map((email)=>{
                let disableValue;
                let emailLabel;
                
                if (email.Email===null){
                    disableValue=true;
                    emailLabel=email.Title + ": " + email.FullName + " *NO EMAIL";
                }else{
                    disableValue=false;
                    emailLabel=email.Title + ": " + email.FullName
                }

                return {value:email.Email, 
                        label:emailLabel,
                        isDisabled:disableValue}
            });
            setEmailList(formattedEmails);
        };

        return ()=>{
            abortController.abort();
        };

    },[contacts]);

    const HandleEmailChange = (e)=>{
        let emailToAdd = e[e.length-1].value;
        let mailArray = [...mailto];
        mailArray[mailArray.length] = [`${emailToAdd};`]
        emailListString = mailArray.join('').toString();
        setMailto(emailListString);
    };
      
    const HandleSubmit = (e)=>{

        let subject="This is a test Email";
        setShowTable(true);
        setModalController(2);
        window.location.href = `mailto:${mailto}?&subject=${subject}`;     
    };

    const closeTable = (e) => {
        setShowTable(false);   
    };

    const HandleCopy = (e) => {
        const copiedTable = document.getElementById('invoiceTable');

        // copiedTable.innerHTML = (copiedTable.children[0].outerHTML + copiedTable.children[1].outerHTML)
        // let myJson = JSON.stringify(copiedTable.outerHTML);
        // console.log(myJson);
        let range, sel;

        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNode(copiedTable);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(copiedTable);
                sel.addRange(range);
            }
            document.execCommand('copy');
        }
        sel.removeAllRanges();
        // console.log("You copied some stuff");
        closeTable();
    }

    return (
        <>
        {modalController===1?
            <ContactListView
                mailto={mailto}
                subject={subject}
                HandleSubmit={HandleSubmit}
                contacts={contacts&&contacts.data}
                show={showMailer}
                showTable={showTable}
                onHide={onHide}                 
                closeTable={closeTable}
                emailList={emailList}
                HandleEmailChange={HandleEmailChange}
                selectedRowData={selectedRowData}
                modalController={modalController}
                setModalController={setModalController}
            />
            :
            <TablePopupView 
                showTable={showTable}
                closeTable={closeTable}
                selectedRowData={selectedRowData}
                HandleCopy={HandleCopy}
            />
        }
        </>
    );
};