import React, { useContext } from 'react';
import CustomerContext from '../Context/CustomerContext';
import { Navigate } from 'react-router-dom';

function PublicRoute({ children }) {
  const {
    cookies
  } = useContext(CustomerContext)
  const authed = (!(cookies.get('customer')==null))

  return authed === false
    ? children
    : <Navigate to="/Dashboard/" replace />;
}

export default PublicRoute;