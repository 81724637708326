import React from 'react';
// import { MDBFooter, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Navbar, Row, Col, } from 'react-bootstrap'
import './Styles/Footer.css'
import Package from '../package.json'
const Footer = () =>{
    return (
    //     <MDBFooter className='text-center text-lg-left footer'>
    //         <MDBRow className="footer-link">
    //             <MDBCol>
    //                 <a className="text-white" href='https://ewm.employnet.com/'>
    //                     EWM.employnet.com
    //                 </a>
    //             </MDBCol>
    //             <MDBCol>
    //                 <a className="text-white" href='/Privacy'>
    //                     Privacy
    //                 </a>
    //             </MDBCol>
    //             <MDBCol>
    //                 <p>App Version: {Package.version}</p>
    //             </MDBCol>
    //         </MDBRow>
    //         <MDBRow>
    //             <div className='text-center p-3'>
    //                 &copy; {new Date().getFullYear()} Copyright:{' - '}
    //                 <p>Employnet Workforce Management</p>
    //             </div>
    //         </MDBRow>
    //   </MDBFooter>
    <Navbar className='text-center text-lg-left footer' fixed="bottom">
        <Row className="footer-link">
            <Col>
                <a className="text-white" href='https://ewm.employnet.com/'>
                    EWM.employnet.com
                </a>
            </Col>
            <Col>
                <a className="text-white" href='/Privacy'>
                    Privacy
                </a>
            </Col>
            <Col>
                <p>App Version: {Package.version}</p>
            </Col>
        </Row>
        <Row>
            <div className='text-center p-3'>
                &copy; {new Date().getFullYear()} Copyright:{' - '}
                <p>Employnet Workforce Management</p>
            </div>
        </Row>
    </Navbar>
    )
};
export default Footer;