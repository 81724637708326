import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Alert, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import AllBranchesLogic from '../AllBranches/AllBranchesLogic'
import { BsDownload } from "react-icons/bs";
import AdvancedSearchLogic from '../AdvancedSearch/AndvancedSearchLogic';
import NewStatusPopup from '../StatusPopup/NewStatusPopup';
import CandidateInfoPopupLogic from '../CandidateInfoPopup/CandidateInfoPopupLogic';
import PopupErrorBoundary from '../PopupErrorBoundary/PopupErrorBoundary'

function Grid({ onNewColumnsLoaded, candidateInfo, infoPopupIsOpen, setInfoPopupIsOpen, OpenInfoPopup, vaxStatusPopup, FilterAll, FilterCustomer, FilterToBeCalled, defaultColDef, HandleClick, OnGridReady, columnDefs, loading, setLoading, ChangeView, setGridData, advancedView, gridData, onBtnExport, currentGrid, popupInfo }){

    
    return(
    <div className='filter-wrapper grid-center'>
        {infoPopupIsOpen&&<PopupErrorBoundary><CandidateInfoPopupLogic candidateInfo={candidateInfo} open={infoPopupIsOpen} setOpen={setInfoPopupIsOpen} /></PopupErrorBoundary>}
        <NewStatusPopup open={vaxStatusPopup} popupInfo={popupInfo} />
        <div align="center" className='grid-download'>
            <Button variant='secondary' onClick={() => onBtnExport()}><BsDownload/>
            </Button>
        </div>    
        {(currentGrid==='Vendor')?
        <div className="r2-c1 filter-branches grid-center">
            {/* Display nothing on purpose. Might put a Vendor dropdown here later */}
        </div>
        :
        <>
        {advancedView ?
            <AdvancedSearchLogic currentGrid={currentGrid} setLoading={setLoading} setData={setGridData}></AdvancedSearchLogic>
            :
            <div className="r2-c1 filter-branches grid-center">
            <AllBranchesLogic className='full-width' ></AllBranchesLogic>
        </div>
        }
        <div className='advanced-search-button'>
            {advancedView ? 
                <Button variant='secondary' onClick={ChangeView}>Basic Search</Button>
                :
                <Button variant='secondary' onClick={ChangeView}>Advanced Search</Button>
            }
        </div>
        </>}
        <div className="filter-menu grid-center">
            <ToggleButtonGroup className='full-width' type="radio" name="filters" defaultValue={1}>
                <ToggleButton id="tbg-radio-1" onClick={FilterAll} variant="secondary" value={1}>All</ToggleButton>
                <ToggleButton id="tbg-radio-2" onClick={FilterToBeCalled} variant="secondary" value={2}>To-Do</ToggleButton>
                <ToggleButton id="tbg-radio-3" onClick={FilterCustomer} variant="secondary" value={3}>Customer</ToggleButton>
            </ToggleButtonGroup>
        </div>
        {loading.loading ?
            <div className="r2-c3 filter-messages grid-center">
                <Alert className='full-width' variant="warning">{loading.message}</Alert>
            </div>
        :
            <div className='r2-c3 filter-messages grid-center'>
                <Alert className='full-width' variant="info">{gridData.length} total assignments</Alert>
            </div>
        }
        <div id="myGrid" className="ag-theme-alpine grid filter-grid">
            <AgGridReact
                defaultColDef={defaultColDef}
                rowData={gridData}
                onCellClicked={OpenInfoPopup}
                columnDefs={columnDefs}
                onGridReady={OnGridReady}
                suppressExcelExport={true}
                popupParent={document.body}
                onNewColumnsLoaded={onNewColumnsLoaded}
            ></AgGridReact>
        </div>
    </div>
    )
}

export default Grid;