import React, { useState, useContext } from 'react'
import CustomerContext from '../../../Context/CustomerContext';
import CustomerInfoPopupView from './CustomerInfoPopupView';
import useFetchGet from '../../../CustomHooks/useFetchGet';
//import Emailer from '../../../CustomComponents/Emailer/Emailer';

export default function CustomerInfoPopup({
    selectedCustomerID,
    show,
    onHide
}){
    const [gridApi, setGridApi] = useState();
    const [contactsDefaultColDef] = useState ({
        sortable: true,
        resizable: true,
        filter: false        
    });
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_ORDER_REQUEST_API_URL } = process.env;
    const { data: contacts } = useFetchGet(`${REACT_APP_ORDER_REQUEST_API_URL}/getcustomercontacts?customerID=${selectedCustomerID}&token=${cookies.get('customer').Token}`, cookies);

    const [contactsColDefs] = useState([
        {field: 'Title'},
        {field: 'FullName', headerName: "Name"},
        {field: 'Phone',
        cellRenderer: function (Phone){
            return '<span><a href="tel:'+Phone.value+'">'+Phone.value+'</a></span>'
         }},
        {field: 'Email',
         cellRenderer: function (Email){
            return '<span><a href="mailto:'+Email.value+'">'+Email.value+'</a></span>'
         }},
    ]);

    const contactsOnGridReady = (params) => { 
        setGridApi(params.api);
        params.api.sizeColumnsToFit()
      };

    return (
        <CustomerInfoPopupView
            contacts={contacts&&contacts.data}
            contactsDefaultColDef={contactsDefaultColDef}
            contactsColDefs={contactsColDefs}
            contactsOnGridReady={contactsOnGridReady}
            show={show}
            onHide={onHide}
        />
    );
    
};