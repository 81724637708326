import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomerContext from "../Context/CustomerContext"
import AllBranches from './AllBranches'

function AllBranchesLogic(){
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const [allBranches, setAllBranches] = useState();
    const{
        cookies,
        selectedBranch,
        setSelectedBranch
    } = useContext(CustomerContext)
    const navigate = useNavigate();

    useEffect(()=>{
        GetAllBranches()
        // eslint-disable-next-line
    },[])

    function GetAllBranches(){
        if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/GetBranches?token=${cookies.get('customer').Token}`)
        .then(res=>{
            if(res.status===200){
                return res.json()
            }else if(res.status===401){
                cookies.remove('customer',{path:'/'})
                navigate('/')
            }else{
                new Error(res)
            }
        })
        .then(data=>{
            TransformBranches(data)
        })
        .catch(err=>console.error(err))
    }

    function TransformBranches(branches){
        const transformedBranches = branches.map((branch)=>{
            if(branch.BranchName===cookies.get('customer').BranchName&&!selectedBranch){
                setSelectedBranch(branch)
                return {value: branch, label: branch.BranchName}
            }else{
                return {value: branch, label: branch.BranchName}
            }
        })
        transformedBranches.unshift({value:{branchName:null},label:'All'})
        setAllBranches(transformedBranches)
    }

    function SetSelectedBranch(e){
        setSelectedBranch(e.value)
    }

    return(
        <AllBranches
            SetSelectedBranch={SetSelectedBranch}
            allBranches={allBranches}
            selectedBranch={selectedBranch}
        ></AllBranches>
    )
}

export default AllBranchesLogic;